exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ecoline-js": () => import("./../../../src/pages/ecoline.js" /* webpackChunkName: "component---src-pages-ecoline-js" */),
  "component---src-pages-fliz-js": () => import("./../../../src/pages/fliz.js" /* webpackChunkName: "component---src-pages-fliz-js" */),
  "component---src-pages-flizelin-js": () => import("./../../../src/pages/flizelin.js" /* webpackChunkName: "component---src-pages-flizelin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-porulonka-js": () => import("./../../../src/pages/porulonka.js" /* webpackChunkName: "component---src-pages-porulonka-js" */),
  "component---src-pages-slobozhanskie-js": () => import("./../../../src/pages/slobozhanskie.js" /* webpackChunkName: "component---src-pages-slobozhanskie-js" */)
}

